
#about .frame{
    display: flex; 
    justify-content: center;
    align-items: center;
}

#about div iframe {
    height: 50vw;
    max-height: 500px;
    max-width: 1000px;
    padding: 0 !important;
}
