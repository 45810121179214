.main {
    height: 90vh;
    overflow: hidden;
    background-image: url('../../../public/food.PNG');
    background-size: cover; 
}

#event-space {
    margin: auto;
    max-width: 600px;
}

.myNav {
    background-color: #111419;
    /* height: 10vh; */
}

@media (max-width: 576px) {
    .hidden-xs {
      display: none !important;
    }

    .box {
        width: 100% !important;
    }

}

.box {
    height: 300px;
    width: 300px;
    background-color: rgb(0, 0, 0, .7);
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
}

.white {
    background-color: rgb(255, 255, 255, .7);
}

.light-text {
    color: white;
}



#circle_v13_wrap {
    width: 250px;
    height: 215px;
}

#circle_v13 {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    height: 100%;
    font: 400 10px/normal 'Arial', sans-serif;
    font-family: 'Arial', sans-serif;
    align-items: center;
    text-align: center;
    color: #000 !important;
    background: url('../../../public/Classic-black-badge-2.svg') no-repeat;
    background-size: cover;
    box-sizing: border-box;
}

#circletype_v13_brand_name {
    position: absolute;
    bottom: 16.2%;
    width: 85%;
    height: 19.74%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    text-transform: uppercase;
}

#circletype_v13_brand_name>a {
    color: #fff;
    text-decoration: none;
    text-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
    font-weight: 700;
}

#circle_v13 #rewards_link {
    position: absolute !important;
    top: 9%;
    display: block !important;
    color: #ffffff00 !important;
    width: 75%;
    height: 40%;
}