/* .react-pdf__Document {
    text-align: center !important;
}

.react-pdf__Page__canvas {
    display: initial !important;
}

.react-pdf__Page__textContent {
    display: none !important;
} */

div iframe {
    height: calc(100vh - 80px) !important;
    padding: 0 !important;
}

.row {
    margin: 0 !important;
}